
import {Options, Vue} from 'vue-class-component'
import {AgentShort} from '@/composables/agents/agents-models'


@Options({
  props: {
    agent: {
      type: [Object, null]
    },
    onClose: Function
  },
  watch: {
    agent(value) {
      this.model = value
    }
  },
  methods: {
    close(): void {
      this.model = null
      this.onClose()
    }
  },
})
export default class ModalCard extends Vue {
  model: AgentShort | null = null;
}
