
import {Options, setup, Vue} from 'vue-class-component'
import {LimitPageDefault, LimitPageVariants} from '@/composables/constants'
import {OrdersModels, OrdersStatuses} from '@/composables/orders/types'
import ModalCard from '@/components/orders/ModalCard.vue'
import ModalDoubles from '@/components/agents/ModalDoubles.vue'
import {list, SortingDefault} from '@/composables/orders/list'
import {Search, Sorting} from '@/services/types'
import useEmitter from '@/composables/useEmitter'
import {positiveInt} from '@/composables/mask'
import {SearchDefault} from '@/composables/agents/list'
import DatePicker from '@/components/core/DatePicker.vue'
import {AgentShort} from '@/composables/agents/agents-models'

@Options({
  components: {
    ModalCard,
    ModalDoubles,
    DatePicker
  },
})
export default class List extends Vue {
  showOptions = false
  page = 1
  limit = LimitPageDefault.value
  sorting: Sorting = SortingDefault

  selected: null | OrdersModels = null
  selectedAgent: null | AgentShort = null
  statuses = OrdersStatuses
  filterStatusId: null | number = null
  filterExportedAtFrom: Date = new Date()
  filterExportedAtTo: Date = new Date()

  search: Search = SearchDefault
  searchFIO: null | string = null
  searchEmail: null | string = null
  searchRef: null | string = null
  searchPromo: null | string = null
  searchPhone: null | string = null
  searchInn: null | string = null


  created(): void {
    this.ctx.emitter.on('orders:refresh', this.fetch)
    this.clear()
  }

  beforeUnmount(): void {
    this.ctx.emitter.off('orders:refresh')
  }

  ctx = setup(() => {
    const {emitter} = useEmitter()
    return {emitter, ...list(), LimitPageVariants}
  })

  getTotalAmount() {
    let sum = 0
    this.ctx.list.forEach((order) => sum += order.amount)
    return sum
  }

  changeSearch() {
    this.searchFIO = null
    this.searchEmail = null
    this.searchRef = null
    this.searchPromo = null
    this.searchPhone = null
    this.searchInn = null
  }


  /**
   * Общая часть
   */

  // триггер на обновление таблицы для новой страницы
  async changePage(): Promise<void> {
    this.page = positiveInt(this.page)
    await this.fetch()
  }

  // Применить фильтры
  async apply() {
    this.page = 1
    await this.fetch()
  }

  // Сбросить фильтры до дефолта
  async clear() {
    this.ctx.reset()
    this.cancel()
    await this.fetch()
  }

  // Отменить введенные фильтры
  cancel() {
    this.page = this.ctx.meta.offset / this.ctx.meta.limit + 1
    this.limit = this.ctx.meta.limit
    this.sorting = this.ctx.sorting
    this.filterStatusId = this.ctx.filterStatusId ?? ''
    this.filterExportedAtFrom = this.ctx.filterExportedAtFrom
    this.filterExportedAtTo = this.ctx.filterExportedAtTo
    this.search = this.ctx.search
    this.searchFIO = this.ctx.searchFIO
    this.searchEmail = this.ctx.searchEmail
    this.searchRef = this.ctx.searchRef
    this.searchPromo = this.ctx.searchPromo
    this.searchPhone = this.ctx.searchPhone
    this.searchInn = this.ctx.searchInn
    this.close()
  }

  close() {
    this.showOptions = false
  }

  scroll() {
    document.getElementById('table')?.scrollTo({top: 0, behavior: 'smooth'})
  }

  async fetch() {
    this.ctx.setLimit(this.limit)
    this.ctx.setPage(this.page)
    this.ctx.setSorting(this.sorting)

    this.ctx.setFilterStatusId(this.filterStatusId)
    this.ctx.setFilterExportedAtFrom(this.filterExportedAtFrom)
    this.ctx.setFilterExportedAtTo(this.filterExportedAtTo)
    this.ctx.setSearch(this.search)
    this.ctx.setSearchFio(this.searchFIO)
    this.ctx.setSearchEmail(this.searchEmail)
    this.ctx.setSearchRef(this.searchRef)
    this.ctx.setSearchPromo(this.searchPromo)
    this.ctx.setSearchPhone(this.searchPhone)
    this.ctx.setSearchInn(this.searchInn)

    await this.ctx.fetch()
    this.close()
    this.scroll()
  }
}
